import React, { lazy } from 'react';

const Hero = lazy(() => import('./Hero'));
const Digital = lazy(() => import('./Digital'));
const Products = lazy(() => import('./Products'));
const About = lazy(() => import('./About'));
const Contact = lazy(() => import('./Contact'));

const Landing = () => {
  return (
    <div className="container mt-header">
      <div
        className="container"
        style={{ background: "#FBFBFB", width: "100%" }}
      >
        <Hero />
        <Digital />
      </div>
      <Products />
      <About />
      <Contact />
  </div>
  );
};


export default Landing;

import "./styles/Feature.css";
import { Fade } from "react-reveal";

const Feature = () => {
  return (
    <div className="feature">
      <Fade top>
        <h1 className="heading">企業版功能說明</h1>
      </Fade>
      <div className="feature-box">
        <Fade delay={500} left>
          <img src="/assets/image 10.png" alt="" />
        </Fade>
        <div className="right-feature-box">
          <h3 className="number">1</h3>
          <Fade delay={500} collapse right>
            <h2>師生溝通</h2>
            <br />
            <h2>點名出勤＋數位聯絡簿</h2>
            <p>
              企業可以透過系統掌握教職員與學生的出缺席，老師與家長透過APP請假後，系統即自動紀錄各項請假資料，方便進行班務管理。
            </p>
            <p>
              每日的教學進度、作業與考試皆可透過數位聯絡簿來傳達給家長，輕鬆又省時。
            </p>
          </Fade>
        </div>
      </div>
      <div className="feature-box">
        <Fade right>
          <img src="/assets/Frame 3388.png" alt="" />
        </Fade>
        <div className="right-feature-box">
          <h3 className="number">2</h3>
          <Fade collapse left>
            <h2>學習記錄</h2>
            <br />
            <h2>紀錄成績＋活動相簿</h2>
            <p>
              學生成績是家長最重視的項目之一，CLASS班級管理系統可以記錄單科考試與大考、模擬考的成績，並透過日期來快速查詢，供老師快速掌握學生的學習狀況。
            </p>
            <p>
              另外，也有班級相簿的功能可以記錄班級活動花絮，供教師、家長與學生透過APP查看與下載，將每個精彩瞬間保存下來。
            </p>
          </Fade>
        </div>
      </div>
      <div className="feature-box">
        <Fade left>
          <img src="/assets/Frame 3387.png" alt="" />
        </Fade>
        <div className="right-feature-box">
          <h3 className="number">3</h3>
          <Fade collapse right>
            <h2>校務行政管理</h2> <br />
            <h2>收費財務＋人事管理</h2>
            <p>
              企業可於CLASS班級管理系統紀錄如學生繳費、教職員薪資、其他庶務等財務收支明細，幫助你快速掌握當月營收。
            </p>
            <p>
              系統為教育業者提供彈性化的排班、假勤、出缺勤管理功能，透過班表、課表一手掌握職員動態。
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Feature;

import "./styles/Hero.css";
import { Fade } from "react-reveal";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const Hero = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [image, setImage] = useState("/assets/背景.png");

  const go = (path) => {
    if (location.pathname === "/") {
      goView(path);
    } else {
      navigate("/");
      setTimeout(() => {
        goView(path);
      }, 500);
    }
  };

  const goView = (path) => {
    let anchorElement = document.getElementById(path);
    if (anchorElement) {
      anchorElement.scrollIntoView();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1028) {
        setImage('/assets/背景.png');
      }
      if (width <= 1028) {
        setImage('/assets/背景2.png');
      }
      if (width <= 640) {
        setImage('/assets/背景3.png');
      }
    };

    handleResize();

    // 偵測螢幕尺寸變化
    window.addEventListener("resize", handleResize);

    // 在組件卸載時清除事件監聽器
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="class-hero class">
      <Fade>
        <img src={image} alt="" />
      </Fade>
      <div className="class-container">
        <div className="class-content">
          <Fade duration={1000} left>
            <h1>CLASS班級管理系統</h1>
          </Fade>
          <Fade delay={200} duration={1000} left>
            <p>操作簡易、功能全面，是您管理補習班的最佳夥伴</p>
          </Fade>
          <Fade delay={400} duration={1000} left>
            <a onClick={() => go("contact")}>聯絡我們</a>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import "./styles/Function.css";
import { Fade } from "react-reveal";

const Function = () => {
  return (
    <div className="func">
      <Fade top>
        <h1 className="heading">功能說明</h1>
      </Fade>
      <div className="func-row">
        <Fade left>
          <img src="/assets/func1.png" alt="" />
        </Fade>
        <div>
          <Fade collapse top>
            <h2>任何時刻都能即時發問</h2>
            <p>
              CLASS問答平台擁有最完整的家教團隊，學生可以在任何時刻、任何地點針對在課業上的困難進行發問，平台會將問題依據年級與科目配對給合適的家教，你也可以指定喜愛的家教進行發問，讓你快速獲得正確解答。
            </p>
          </Fade>
        </div>
      </div>
      <div className="func-row">
        <Fade right>
          <img src="/assets/func2.png" alt="" />
        </Fade>
        <div>
          <Fade left collapse>
            <h2>指定適合的教師尋求協助</h2>
            <p>
              CLASS平台提供優選教師推薦，讓學生輕鬆找到好老師，即時為學生解惑。學生可以根據個人需求來尋找並指定適合自己的教師，在問答結束後也能將教師加入最愛，下次還有任何疑問時直接指定最愛教師進行發問。
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Function;
